<template>
  <div class="classify-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses'}">教学内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses/min-classify'}">小分类管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑小分类</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <el-form-item label="归属分类" prop="tid_classify">
          <el-select v-model="pageForm.tid_classify" placeholder="请选择归属分类">
            <el-option :label="item.title" :value="item.m_id" v-for="item in classifyList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小分类标题" prop="title">
          <el-input v-model="pageForm.title"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即更新</el-button>
          <el-button @click="back">返 回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MinClassifyEdit',
  data () {
    return {
      classifyList: [],
      pageForm: {
        m_id: this.$route.params.mid,
        tid_classify: '',
        title: ''
      },
      pageRules: {
        tid_classify: [
          { required: true, message: '请选择归属专题', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写专题分类标题', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getClassifyList()
  },
  methods: {
    // 获取分类列表
    async getClassifyList () {
      const { data: res } = await this.$http.get('/classify-list')
      if (res.code === 200) {
        this.classifyList = res.data
        await this.getItemInfo()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取编辑内容
    async getItemInfo () {
      const { data: res } = await this.$http.get('/min-classify', { params: { mid: this.$route.params.mid } })
      if (res.code === 200) {
        this.pageForm.tid_classify = res.data.tid_classify
        this.pageForm.title = res.data.title
      } else {
        this.$message.error(res.msg)
      }
    },
    submit () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/min-classify', this.pageForm)
          if (res.code === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/content/courses/min-classify')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    back () {
      this.$router.push('/admin/content/courses/min-classify')
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.page-form{
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}
</style>
